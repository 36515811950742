import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LatQuotesIntro'

// markup
const LatQuotes_m = () => {
  return (
   <Layout >
      <Head title='Latin Quotes and Expressions - M'/>
     <h1>LATIN QUOTES AND EXPRESSIONS</h1>
     <h2>M</h2>
     <LatQuotesIntro />
    
     <p><b>Maecenas atavis edite regibus</b> - Maecenas, born of monarch ancestors. (Horace)</p>
<p><b>Magister artis ingeniique largitor venter</b> - Necessity is the mother of all invention</p>
<p><b>Magister Artium (MA)</b> - Master of arts</p>
<p><b>Magister mundi sum!</b> - I am the master of the universe!</p>
<p><b>Magna cum laude</b> - With great honour or academic distinction</p>
<p><b>Magna res est vocis et silentii temperamentum</b> - The great thing is to know when to speak and when to keep quiet</p>
<p><b>Magnas inter opes inops</b> - A pauper in the midst of wealth. (Horace)</p>
<p><b>Magnificat</b> - It magnifies</p>
<p><b>Magnum bonum</b> - A great good</p>
<p><b>Magnum opus</b> - Great work, the major work of one's life</p>
<p><b>Mala fide</b> - In bad faith (something which is done fraudulently)</p>
<p><b>Male parta male dilabuntur</b> - What has been wrongly gained is wrongly lost.(Cicero)</p>
<p><b>Malum consilium quod mutari non potest</b> - It's a bad plan that can't be changed. (Publilius Syrus)</p>
<p><b>Malum prohibitum</b> - A prohibited wrong. A crime that society decides is wrong for some reason, not inherently evil</p>
<p><b>Malum quidem nullum esse sine aliquo bono</b> - There is, to be sure, no evil without something good. (Pliny the Elder)</p>
<p><b>Manus in manu</b> - Hand in hand</p>
<p><b>Manus manum lavat</b> - One hand washes the other. The favor for the favor. (Petronius)</p>
<p><b>Mare clausum</b> - A closed sea</p>
<p><b>Mare liberum</b> - An open sea</p>
<p><b>Mare nostrum</b> - Our sea. (Mediterranean)</p>
<p><b>Margaritas ante porcos</b> - Pearls before swine. To give something valuable to someone not respecting it</p>
<p><b>Mater artium necessitas</b> - Necessity is the mother of invention</p>
<p><b>Mater dolorosa</b> - Sorrowful mother. (Virgin Mary)</p>
<p><b>Materfamilias</b> - Mother of family</p>
<p><b>Materia medica</b> - Medical matter</p>
<p><b>Materiam superabat opus</b> - The workmanship was better than the subject matter. (Ovid)</p>
<p><b>Maxima debetur puero reverentia</b> - We owe the greatest respect to a child</p>
<p><b>Maximus in minimis</b> - Great in little things</p>
<p><b>Me fallit</b> - I do not know</p>
<p><b>Me iudice</b> - In my judgement</p>
<p><b>Mea culpa</b> - Through my fault</p>
<p><b>Mea maxima culpa</b> - Through my very great fault</p>
<p><b>Mea mihi conscientia pluris est quam omnium sermo</b> - My conscience means more to me than all speech. (Cicero)</p>
<p><b>Medice, cura te ipsum!</b> - Physician, heal thyself! (Versio Vulgata)</p>
<p><b>Medici graviores morbos asperis remediis curant</b> - Doctors cure the more serious diseases with harsh remedies. (Curtius Rufus)</p>
<p><b>Medicus curat, natura sanat</b> - The physician treats, nature cures</p>
<p><b>Medio tutissimus ibis</b> - You will go safest in the middle. (Moderation in all things) (Ovid)</p>
<p><b>Meliora cogito</b> - I strive for the best</p>
<p><b>Melius est praevenire quam praeveniri</b> - Better to forestall than to be forestalled</p>
<p><b>Melius frangi quam flecti</b> - It is better to break than to bend</p>
<p><b>Memento mori</b> - Remember that you must die</p>
<p><b>Memento vivere</b> - A reminder of life (literally remember that you have to live)</p>
<p><b>Memorabilia</b> - Memorable things</p>
<p><b>Memorandum</b> - A note of; a thing to be remembered</p>
<p><b>Memoria in aeterna</b> - In everlasting remembrance</p>
<p><b>Memoriter</b> - From memory</p>
<p><b>Mendacem memorem esse oportet</b> - A liar needs a good memory. (Quintilianus)</p>
<p><b>Mens agitat molem</b> - The mind moves the matter. (Vergil)</p>
<p><b>Mens rea</b> - Guilty mind</p>
<p><b>Mens regnum bona possidet</b> - An honest heart is a kingdom in itself. (Seneca)</p>
<p><b>Mens sana in corpore sano</b> - A sound mind in a sound body. (Juvenalis)</p>
<p><b>Mens sibi conscia recti</b> - A mind conscious of its rectitude</p>
<p><b>Meum pactum dictum</b> - My word is my bond</p>
<p><b>Mihi cura futuri</b> - My concern is the future</p>
<p><b>Millennium (millennia)</b> - A thousand year period</p>
<p><b>Mirabile dictu</b> - Wonderful to say/relate</p>
<p><b>Mirabile visu</b> - Wonderful to behold</p>
<p><b>Miserere</b> - Have mercy</p>
<p><b>Missa solemnis</b> - Solemn Mass. (high Mass)</p>
<p><b>Mittimus</b> - We send (to prison)</p>
<p><b>Modus agendi</b> - Manner of operation</p>
<p><b>Modus operandi (m.o.)</b> - Way of operating</p>
<p><b>Modus vivendi</b> - Way of living</p>
<p><b>Monstra mihi pecuniam!</b> - Show me the money!</p>
<p><b>Moratorium</b> - A delay</p>
<p><b>Morituri te salutant</b> - Those who are about to die salute you</p>
<p><b>Mors ultima linea rerum est</b> - Death is everything's final limit. (Horace)</p>
<p><b>Mors ultima ratio</b> - Death is the final accounting</p>
<p><b>Mortui non mordant</b> - Dead me don't bite; Dead men tell no tale</p>
<p><b>Motu proprio</b> - Of one's own initiative</p>
<p><b>Mulier taceat in ecclesia</b> - Let the woman be silent in church. (Paul)</p>
<p><b>Multi famam, conscientiam pauci verentur</b> - Many fear their reputation, few their conscience. (Pliny)</p>
<p><b>Multi sunt vocati, pauci vero electi</b> - Many are called [but] few are chosen</p>
<p><b>Multis post annis</b> - Many years later</p>
<p><b>Multum in parvo</b> - Much in little. (small but significant)</p>
<p><b>Multum, non multa</b> - Much, not many (quality not quantity)</p>
<p><b>Mundus vult decipi</b> - The world wants to be deceived</p>
<p><b>Mundus vult decipi, ergo decipiatur</b> - The world wants to be deceived, so let it be deceived!</p>
<p><b>Munit haec et altera vincit</b> - One defends and the other conquers</p>
<p><b>Mus uni non fidit antro</b> - A mouse does not rely on just one hole. (Plautus)</p>
<p><b>Musica delenit bestiam feram</b> - Music soothes the savage beast</p>
<p><b>Mutatis mutandis</b> - The necessary changes having been made</p>
<p><b>Mutatis mutandis</b> - The things that ought to have changed having been changed with the necessary substitutions having been made</p>
<p><b>Mutato nomine</b> - The name being changed</p>

   </Layout>
  )
}

export default LatQuotes_m
